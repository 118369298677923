import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

import ChevronBottomIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronBottomIcon";
import Layer from "@finanzchef24gmbh/design-system/src/Layer";
import { NavigationMenu_ContentfulNavigationMenuFragment } from "../../../../types/graphql-types";
import { trackMainNavigationLink } from "../../../utils/trackingUtils";
import RelativeLink from "../../RelativeLink";
import SubLevelNavigation from "./SubLevelNavigation";

const StyledTopLevelUl = styled.ul`
  display: none;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    display: inline-flex;
    flex-wrap: wrap;
  }
`;

const StyledSubLevelContainer = styled.div`
  visibility: hidden;
  position: absolute;
  left: 50%;

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    /*
    * Fixes a Safari rendering intersection bug that causes an image with transform: rotateY to
    * partially overlap the dialog.
    * Example page: https://www.finanzchef24.de/versicherung/architekt
    * Solution: https://stackoverflow.com/a/18167565/5133172
    */
    transform: translate3d(-50%, 0, 100vw);
  }
`;

const StyledTopLevelLi = styled.li`
  position: relative;
  margin: ${(props) => props.theme.spacings.medium};

  &:hover ${StyledSubLevelContainer} {
    visibility: visible;
    z-index: 2;
  }

  &:focus-within ${StyledSubLevelContainer} {
    visibility: visible;
    z-index: 1;
  }
`;

const StyledLayerOverlay = styled(Layer.Overlay)<{ hasTwoSubLevels: boolean }>`
  display: flex;
  margin-top: ${(props) => props.theme.spacings.big};
  border-radius: ${(props) => props.theme.borders.radius.medium}px;

  ${(props) =>
    props.hasTwoSubLevels &&
    css`
      transform: translateX(32%);
    `}
`;

const StyledTopLevelLink = styled(RelativeLink)<{
  isOnDarkBackground?: boolean;
}>`
  text-decoration: none;
  color: ${(props) => props.theme.palette.gray.dark};
  font-weight: ${(props) => props.theme.typography.weights.semiBold};

  svg {
    color: ${(props) => props.theme.palette.gray.dark};
  }

  &:hover {
    svg {
      color: ${(props) => props.theme.palette.brand.main};
      transform: rotate(180deg);
    }
  }

  ${(props) =>
    props.isOnDarkBackground &&
    css`
      color: ${props.theme.palette.white};

      svg {
        color: ${props.theme.palette.white};
      }

      &:hover,
      &:hover svg {
        color: ${props.theme.palette.gray[200]};
      }
    `}
`;

type NavigationMenuProps = {
  navigation: NavigationMenu_ContentfulNavigationMenuFragment;
  isOnDarkBackground?: boolean;
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  navigation,
  isOnDarkBackground,
}) => (
  <StyledTopLevelUl>
    {navigation.navigationLevels?.map((level, index) => {
      if (!level) {
        return null;
      }

      const topLevelLinkId = `nav-item-${level.id}`;

      return level.topLevelLink ? (
        <StyledTopLevelLi key={`${level.id}-${index}`}>
          <StyledTopLevelLink
            id={topLevelLinkId}
            href={level.topLevelLink.slug || ""}
            icon={level.subLevelLinks1 ? <ChevronBottomIcon /> : <></>} // Link element styles differently when an Icon is not present so we just pass an empty fragment
            iconPosition="right"
            isOnDarkBackground={isOnDarkBackground}
            onClick={() => trackMainNavigationLink(level.topLevelLink?.slug)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: level.topLevelLink.label || "",
              }}
            />
          </StyledTopLevelLink>

          {level.subLevelLinks1 ? (
            <StyledSubLevelContainer>
              <StyledLayerOverlay
                hasTwoSubLevels={Boolean(level.subLevelLinks2)}
              >
                <SubLevelNavigation
                  subLevelHeading={level.subLevelHeading1}
                  subLevelLinks={level.subLevelLinks1}
                  labelledBy={topLevelLinkId}
                />

                {level.subLevelLinks2 ? (
                  <SubLevelNavigation
                    subLevelHeading={level.subLevelHeading2}
                    subLevelLinks={level.subLevelLinks2}
                    labelledBy={topLevelLinkId}
                    totalLinksSubLevel1={level.subLevelLinks1.length}
                    isSubLevel2
                  />
                ) : null}
              </StyledLayerOverlay>
            </StyledSubLevelContainer>
          ) : null}
        </StyledTopLevelLi>
      ) : null;
    })}
  </StyledTopLevelUl>
);

export const navigationMenuFragment = graphql`
  fragment NavigationMenu_ContentfulNavigationMenu on ContentfulNavigationMenu {
    navigationLevels {
      id
      topLevelLink {
        label
        slug
      }
      subLevelHeading1
      subLevelLinks1 {
        label
        slug
        highlightColor
        designSystemIcon
      }
      subLevelHeading2
      subLevelLinks2 {
        label
        slug
        highlightColor
        designSystemIcon
      }
    }
  }
`;

export default NavigationMenu;
